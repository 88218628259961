<template>
  <ModalNuevo class="search-modal"
              :open="open"
              data-test-id="search-modal"
              @close="close">
    <template #modal-header>
      <div class="search-modal__header">
        <SearchInput :placeholder="t('search.placeholder')" @search="gatherPredictions" />
      </div>
    </template>

    <div class="search-modal__content" data-test-id="search-results">
      <template v-if="state.count > 0 && state.query">
        <div class="search-modal__title">
          {{ t('marketplace_search.good_matches') }}
        </div>
        <SearchResult v-for="establishment in state.establishments"
                      :key="establishment.id"
                      :title="establishment.title"
                      :subtitle="establishment.categories"
                      :image="establishment.image_url"
                      :url="establishment.relative_url" />
      </template>

      <div class="search-modal__text">
        <template v-if="state.count === 0">
          {{ t('marketplace_search.no_results_found_try_again') }}
        </template>
      </div>
    </div>

    <template v-if="state.count > 0 && state.query" #modal-bottom>
      <div class="search-modal__bottom">
        <Button data-test-id="show-all-results-button" @click="showResults">
          {{ buttonText }}
        </Button>
      </div>
    </template>
  </ModalNuevo>
</template>

<script setup>
import { reactive, computed } from 'vue'
import { useStore } from '@/stores'
import { useI18n } from 'vue-i18n'
import SearchResult from './SearchResult.vue'
import Button from '@/components/partials/Buttons/Button/Button.vue'
import ModalNuevo from '@/components/Modals/ModalNuevo/ModalNuevo.vue'
import SearchInput from './SearchInput.vue'
import { useAxios } from '@/composables/useAxios'
import { useGa } from '@/composables/useGa'
import { PRODUCT_SEARCHED } from '@/plugins/globals/segment/handlers'
import { useSegment } from '@/composables/useSegment'

const { t } = useI18n()
const store = useStore()
const ga = useGa()

defineProps({
  open: Boolean,
})

const emit = defineEmits(['close'])
const axios = useAxios()

const state = reactive({
  establishments: [],
  count: null,
  query: '',
})

const location = computed(() => store.getters['session/location'])
const distributionType = computed(() => store.getters['session/distributionType'])

const buttonText = computed(() => {
  if (!state.query) return t('marketplace_search.type_to_search')

  return t('marketplace_search.show_amount_of_results', { amountOfResults: state.count })
})

const showResults = async () => {
  await store.dispatch('marketplace/storeSearchQuery', state.query)

  close()
}

const segment = useSegment()

const gatherPredictions = async (query) => {
  state.establishments = []
  state.count = null

  if (query.length < 2) {
    return
  }

  state.query = query

  const { data: { data: { count, establishments } } } = await axios({
    method: 'GET',
    url: 'establishments/search',
    params: {
      q: query,
      distribution: distributionType.value,
      'location[lat]': location.value?.coordinates?.lat,
      'location[lng]': location.value?.coordinates?.lng,
    },
  })

  state.count = count
  state.establishments = establishments

  segment.handle(PRODUCT_SEARCHED, {
    query: state.query,
    tracking: store.getters['session/tracking']
  })

  ga.trackSearch({ query, count })
}

function close() {
  state.establishments = []
  state.count = null
  state.query = ''

  emit('close')
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/mixins/styling.scss';
.search-modal {
  &__header {
    padding: 2rem 2rem 1rem;
  }

  &__title {
    @include gradient-text;
    max-width: max-content;
    font-weight: 700;
    font-size: 1.25rem;
    margin-bottom: 1.75rem;
  }

  &__text {
    text-align: center;
    width: 100%;
  }

  &__header,
  &__bottom {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
</style>
